<template>
  <div class="user">
    <Layout class="layout">
      <LayoutHeader class="layout-header" v-if="!isChoose">
        项目中心
        <div class="right">
          <span class="menu-item">{{userName}}</span>
          <div class="menu-item loginOut" @click="loginOutFun()"><IconLogout /><span class="text">退出</span></div>
        </div>
      </LayoutHeader>
      <LayoutContent class="layout-content">
        <div class="actionBtns">

        </div>
        <div class="searchRange">
          <Form layout="inline">
            <FormItem>
              <Input v-model:value="search.projectName"
                             placeholder="项目名称"/>
            </FormItem>
            <FormItem>
              <Select
                style="flex: 10;
                width: 100px;"
                v-model:value="search.projectType"
                placeholder="产品类型"
            >
                <SelectOption :value="null">全部</SelectOption>
              <SelectOption :value="0">纸杯</SelectOption>
              <SelectOption :value="1">手提袋</SelectOption>
            </Select>
            </FormItem>
            <FormItem><Button type="primary"  @click="searchList">搜索</Button></FormItem>
            <template v-if="!isChoose">
              <FormItem> <Button @click="newProjectFun">新建项目</Button></FormItem>
              <FormItem>
                <Button @click="newProjectByMould" style="border: #409eff 1px solid;">模板新建项目</Button>
              </FormItem>
              <FormItem>
                <Button @click="uploadImgs"><IconUpload class="icon"/>上传素材</Button>
              </FormItem>
            </template>

          </Form>


        </div>
        <Table
            :dataSource="tableData"
            :columns="columns"
            :pagination="pagination"
            :rowKey='record=>record.id'
            style="margin-top: 10px;"
            @change="handleTableChange">
          <template #projectType="{record}">
            <Tag v-if="record.projectType == 0" color="green">纸杯</Tag>
            <Tag v-if="record.projectType == 1" color="geekblue">手提袋</Tag>
          </template>
          <template #publicFlag="{record}">
            <Tag v-if="record.publicFlag == 0" color="red">私有项目</Tag>
            <Tag v-if="record.publicFlag == 1" color="green">共享项目</Tag>
          </template>
          <template #action="{record }" class="tableAction">
            <template v-if="!isChoose">
            <Button type="primary" class="actionButton" @click="editProject2(record)"><IconEdit/><span>编辑</span></Button>
            &nbsp;
              <Button v-if="record.publicFlag == 0" @click="shareProject(record,1)">共享项目</Button>

              <Button v-else-if="record.publicFlag == 1" @click="shareProject(record,0)">取消共享项目</Button>
            </template>
            <template v-else-if="isChoose">

              &nbsp;<Button @click="chooseThis(record)">选择此项目</Button>
            </template>
          </template>
        </Table>
      </LayoutContent>
    </Layout>
    <Modal
        v-model:visible="newDialogVisible"
        centered
        title="新建项目"
        @ok="newSureBtn"
        okText = '确定'
        cancelText = '取消'
        destroyOnClose>
          <Form
              :label-col="labelCol"
              :wrapper-col="wrapperCol">
            <FormItem label="项目名称">
              <Input v-model:value="projectName" />
            </FormItem>
            <FormItem label='产品类型'>
              <Select
                  style="flex: 10;"
                  v-model:value="projectType"
              >
                <SelectOption :value="0">纸杯</SelectOption>
                <SelectOption :value="1">手提袋</SelectOption>
              </Select>
            </FormItem>
          </Form>
    </Modal>
    <Modal
      v-model:visible="uploadDialogVisible"
      v-if="uploadDialogVisible"
      centered
      title="上传素材"
      :width="800"
      :footer="null">
      <Plupload @onChange="handleFileChange"></Plupload>
    </Modal>
    <Modal
        v-model:visible="mouldDialogVisible"
        v-if="mouldDialogVisible"
        centered
        title="选择模板"
        :width="800"
        :footer="null">
        <user :isChoose="true" @chooseThis ='chooseProject'></user>
    </Modal>
  </div>

</template>

<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue'
import {useRouter} from 'vue-router'
import {getCurrentUser, logout} from '@/api/user'
import {getProject, getProjectByLine, queryProjectList,setProjectPublic} from '@/api/project'
import {removeToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
import { MutationTypes, useStore } from '@/store'
import {createRandomCode} from '@/utils/common'
import Plupload from '@/components/plupload.vue'
import {cupSlides ,reticuleSlides} from '@/mocks/slides'
import {getQueryObject} from '@/utils/utils'

const  columns = [
  // {
  //   title: 'id',
  //   dataIndex: 'id',
  // },
  {
    title: '项目名称',
    dataIndex: 'projectName',
  },
  {
    title: '产品类型',
    slots: {customRender: 'projectType'}
  },
  {
    title: '项目类型',
    slots: {customRender: 'publicFlag'}
  },
  {
    title: '操作',
    slots: { customRender: 'action' },
  },
]
export default defineComponent({
  name: 'user',
  components: {Plupload},
  props:{
    isChoose:{
      type:Boolean,
      default:false
    }
  },
  setup(props,{ emit }) {
    const router = useRouter()
    const store = useStore()
    const theme = computed(() => store.state.theme)

    let pagination = ref({
      pageSize: 10,
      current: 1,
      total: 0,
      showTotal: (total: number) => {
        return `共${total}条`
      }
    })
    let search = ref({
      projectName: '',
      projectType: null
    })

    let tableData = ref([])
    let newDialogVisible = ref(false)
    let projectName = ref('')
    let projectType = ref(0)
    let userName = ref('')
    //新建项目
    const newProjectFun = () => {
      newDialogVisible.value = true
      projectName.value = ''
      projectType.value = 0
    }
    const newSureBtn = () => {
      router.push({
        name: 'Project',
        query: {
          projectType:projectType.value,
          projectName:projectName.value
        }
      })
    }

    // 获取当前用户信息
    const getUserInfo = () => {
      getCurrentUser({}).then(res => {
        // console.log(res)
        userName.value = res.data.userName
      })
    }
    //获取项目列表
    const getList = () => {
      var publicFlag:any = ''
      if(props.isChoose){
        publicFlag = 1
      }else {
        publicFlag = ''
      }
      let params = {
        projectType: search.value.projectType,
        keyWord: search.value.projectName,
        publicFlag:publicFlag
      }
      let page = {
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize
      }
      let data = {
        'params': JSON.stringify(params),
        'page': JSON.stringify(page),
      }
      queryProjectList(data).then(res => {
        // console.log(res)
        tableData.value = res.data
        pagination.value.total = res.total
      })
    }
    const handleTableChange = (page: any) => {
      pagination.value.current = page.current
      getList()
    }
    const searchList = () => {
      getList()
    }


    // 模板创建项目
    let mouldDialogVisible = ref(false)
    const newProjectByMould = ()=>{
      mouldDialogVisible.value = true

    }
    const chooseProject = (row:any)=>{
      mouldDialogVisible.value = false
      router.push({
        name: 'Project',
        query: {
          projectId: row.id,
          createType: 1,
          projectType:row.projectType
        }
      })
    }

    //选择此项目作为模板
    const chooseThis= (row:any)=>{
      emit('chooseThis',row)
    }

    //编辑项目
    const editProject2 = async (row: any) => {
      router.push({
        name: 'Project',
        query: {
          projectId: row.id,
          createType:0,
          projectType:row.projectType
        }
      })
    }

    //加载完成后
    onMounted(() => {
      getUserInfo()
      getList()

    })
    const shareProject = (row: any, flag: number) => {

      let data = {
        idProject: row.id,
        publicFlag: flag
      }
      setProjectPublic(data).then((res) => {
        // console.log(res)
        if (res.httpCode == 200) {
          message.success('操作成功')
          getList()
        }
      })
    }

    //上传素材
    let uploadDialogVisible = ref(false)
    const uploadImgs = () => {
      uploadDialogVisible.value = true
    }
    const handleFileChange = () => {
      uploadDialogVisible.value = false
    }


    //退出登录
    const loginOutFun = () => {
      logout({}).then((res) => {
        // console.log(res)
        if (res.httpCode == 200) {
          removeToken()
          window.location.reload()
        }
      }).catch(err => {
        // message.error(err)
      })
    }
    return {
      tableData,
      columns,
      newDialogVisible,
      projectName,
      projectType,
      pagination,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      handleTableChange,
      newProjectFun,
      newSureBtn,
      newProjectByMould,
      chooseProject,
      loginOutFun,
      editProject2,
      uploadDialogVisible,
      uploadImgs,
      handleFileChange,
      shareProject,
      search,
      searchList,
      userName,
      mouldDialogVisible,
      chooseThis
    }
  }
})

</script>

<style lang="scss" scoped>
  .user {
    height: 100%;
    .gray {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: #555;
      opacity: 0.5;
    }
    .spinClass {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1000;
    }
    .layout {
      height: 100%;
      &-header {
        background-color: #666;
        color: white;
        display: flex;
        flex-wrap: wrap;
        .right {
          flex: 1;
          text-align: end;
          .menu-item {
            display: inline-block;
            margin-left: 20px;
          }
          .loginOut {
            cursor: pointer;
          }
        }
      }
      &-content {
        background-color: white;
        padding: 10px 50px;
      }
      &-footer {
        background-color: #41464b;
      }
    }
  }
</style>