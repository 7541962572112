
import {defineComponent, ref, onMounted, computed} from 'vue'
import {useRouter} from 'vue-router'
import {getCurrentUser, logout} from '@/api/user'
import {getProject, getProjectByLine, queryProjectList,setProjectPublic} from '@/api/project'
import {removeToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
import { MutationTypes, useStore } from '@/store'
import {createRandomCode} from '@/utils/common'
import Plupload from '@/components/plupload.vue'
import {cupSlides ,reticuleSlides} from '@/mocks/slides'
import {getQueryObject} from '@/utils/utils'

const  columns = [
  // {
  //   title: 'id',
  //   dataIndex: 'id',
  // },
  {
    title: '项目名称',
    dataIndex: 'projectName',
  },
  {
    title: '产品类型',
    slots: {customRender: 'projectType'}
  },
  {
    title: '项目类型',
    slots: {customRender: 'publicFlag'}
  },
  {
    title: '操作',
    slots: { customRender: 'action' },
  },
]
export default defineComponent({
  name: 'user',
  components: {Plupload},
  props:{
    isChoose:{
      type:Boolean,
      default:false
    }
  },
  setup(props,{ emit }) {
    const router = useRouter()
    const store = useStore()
    const theme = computed(() => store.state.theme)

    let pagination = ref({
      pageSize: 10,
      current: 1,
      total: 0,
      showTotal: (total: number) => {
        return `共${total}条`
      }
    })
    let search = ref({
      projectName: '',
      projectType: null
    })

    let tableData = ref([])
    let newDialogVisible = ref(false)
    let projectName = ref('')
    let projectType = ref(0)
    let userName = ref('')
    //新建项目
    const newProjectFun = () => {
      newDialogVisible.value = true
      projectName.value = ''
      projectType.value = 0
    }
    const newSureBtn = () => {
      router.push({
        name: 'Project',
        query: {
          projectType:projectType.value,
          projectName:projectName.value
        }
      })
    }

    // 获取当前用户信息
    const getUserInfo = () => {
      getCurrentUser({}).then(res => {
        // console.log(res)
        userName.value = res.data.userName
      })
    }
    //获取项目列表
    const getList = () => {
      var publicFlag:any = ''
      if(props.isChoose){
        publicFlag = 1
      }else {
        publicFlag = ''
      }
      let params = {
        projectType: search.value.projectType,
        keyWord: search.value.projectName,
        publicFlag:publicFlag
      }
      let page = {
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize
      }
      let data = {
        'params': JSON.stringify(params),
        'page': JSON.stringify(page),
      }
      queryProjectList(data).then(res => {
        // console.log(res)
        tableData.value = res.data
        pagination.value.total = res.total
      })
    }
    const handleTableChange = (page: any) => {
      pagination.value.current = page.current
      getList()
    }
    const searchList = () => {
      getList()
    }


    // 模板创建项目
    let mouldDialogVisible = ref(false)
    const newProjectByMould = ()=>{
      mouldDialogVisible.value = true

    }
    const chooseProject = (row:any)=>{
      mouldDialogVisible.value = false
      router.push({
        name: 'Project',
        query: {
          projectId: row.id,
          createType: 1,
          projectType:row.projectType
        }
      })
    }

    //选择此项目作为模板
    const chooseThis= (row:any)=>{
      emit('chooseThis',row)
    }

    //编辑项目
    const editProject2 = async (row: any) => {
      router.push({
        name: 'Project',
        query: {
          projectId: row.id,
          createType:0,
          projectType:row.projectType
        }
      })
    }

    //加载完成后
    onMounted(() => {
      getUserInfo()
      getList()

    })
    const shareProject = (row: any, flag: number) => {

      let data = {
        idProject: row.id,
        publicFlag: flag
      }
      setProjectPublic(data).then((res) => {
        // console.log(res)
        if (res.httpCode == 200) {
          message.success('操作成功')
          getList()
        }
      })
    }

    //上传素材
    let uploadDialogVisible = ref(false)
    const uploadImgs = () => {
      uploadDialogVisible.value = true
    }
    const handleFileChange = () => {
      uploadDialogVisible.value = false
    }


    //退出登录
    const loginOutFun = () => {
      logout({}).then((res) => {
        // console.log(res)
        if (res.httpCode == 200) {
          removeToken()
          window.location.reload()
        }
      }).catch(err => {
        // message.error(err)
      })
    }
    return {
      tableData,
      columns,
      newDialogVisible,
      projectName,
      projectType,
      pagination,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      handleTableChange,
      newProjectFun,
      newSureBtn,
      newProjectByMould,
      chooseProject,
      loginOutFun,
      editProject2,
      uploadDialogVisible,
      uploadImgs,
      handleFileChange,
      shareProject,
      search,
      searchList,
      userName,
      mouldDialogVisible,
      chooseThis
    }
  }
})

